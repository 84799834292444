import React, { useRef } from 'react';
import styled from 'styled-components';

import EmailEditor from '../../../src';
import sample from './sample.json';
// console.log(sample)
// import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Axios from 'axios';
import { useState } from 'react';


// const API_KEY = "2992c9debd79d95734hj89234hx234";
// const dotconversApi = 'http://localhost/dotconverse/api/';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const Bar = styled.div`
  flex: 1;
  background-color: #61dafb;
  color: #000;
  padding: 10px;
  display: flex;
  max-height: 40px;

  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
  }

  button {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #000;
    color: #fff;
    border: 0px;
    max-width: 150px;
    cursor: pointer;
  }
`;

const Example = (props) => {
  const emailEditorRef = useRef();
  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((design) => {
      var templateName = document.getElementById('TemplateName').value;

      if (templateName != "") {
        if (id != undefined && id != "") {
          const updatetemplate = { userTemplate: design, id: id, templatename: templateName };
          Axios.post('https://mk.linkinfortech.com/send_hold_emails/edit_template_data', JSON.stringify(updatetemplate))
            .then(result => {
              console.log(result.data);
            });
        } else {
          fetch('https://mk.linkinfortech.com/send_hold_emails/add_template_data', {
            method: 'post',
             body: JSON.stringify({
              userTemplate: design,
              templatename: templateName
            }),

          }).then(response => response.json())
            .then(data => {
              if (data === 'success') {
                console.log('hiii');
              }
            })


         }
        console.log('saveDesign', design);
        // alert('Design JSON has been logged in your developer console.');
        alert('Design JSON has been save successfully.');
      } else {
        alert('Please Enter Template Name')
      }
    });
  };

  
  const { id } = useParams();
  const [val, setValue] = useState("");
  const [emailtemplate, setEmailtemplate] = useState('');

  const exportHtml = async () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
       alert('Output HTML has been saved successfully.');
      if (id != undefined) {
        const usertemplate = { userTemplate: html, id: id };
        Axios.post('https://mk.linkinfortech.com/send_hold_emails/edit_htmltemplate_data', JSON.stringify(usertemplate))
          .then(result => {
            window.close();
          });
      } else {
        const usertemplate = { userTemplate: html };
        Axios.post('https://mk.linkinfortech.com/send_hold_emails/add_htmltemplate_data', JSON.stringify(usertemplate))
          .then(result => {
          window.close();
          });
      }
    });
  };

  

  const onDesignLoad = (data) => {
    console.log('onDesignLoad', data);
  };

  const onLoad = () => {
    console.log('onLoad');

    emailEditorRef.current.editor.addEventListener(
      'design:loaded',
       onDesignLoad
    );
    
    if (id != undefined && id != "") {
      const templateId = { id: id };
      Axios.post('https://mk.linkinfortech.com/send_hold_emails/fetch_template_data', JSON.stringify(templateId))
        .then(result => {
          document.getElementById("TemplateName").value = result.data.tempname;
         emailEditorRef.current.editor.loadDesign(JSON.parse(result.data.data));
          });
    } else {
      const defaulttemplate = {id:1}
      Axios.post('https://mk.linkinfortech.com/send_hold_emails/fetch_template_data', JSON.stringify(defaulttemplate))
      .then(result => {
        document.getElementById("TemplateName").value = result.data.tempname;
       emailEditorRef.current.editor.loadDesign(JSON.parse(result.data.data));

        });
     
    }
  }

  const onReady = () => {
    console.log('onReady');
  };



  return (
    <Container>
     
      <Bar>
        <h1>Linkinfotech Editor</h1>
      
        <img src="https://mk.linkinfortech.com/assets/images/loaders/info.png" style={{ marginTop: 5,width: 25,height: 25,marginRight: 4 }} title="For Personalization , Please Click Tag from Drop Down  Copy and Paste as on requirement"></img>
        <select id="personalize" onChange={copytag} style={{ marginRight: 9}}>
          <option value="">Select Personalize tag</option>
          <option value="#user_link/">User Link</option>
          <option value="#user_email/">User Email</option>
          <option value="#first_name">First Name</option>
          <option value="#last_name">Last Name</option>
          <option value="#company">Company</option>
          <option value="#unsubscribe">Unsubsribe</option>
          <option value="#industry">Industry</option>
          <option value="#designation">Designation</option>

        </select>
        
        <input type="text" id="TemplateName"  placeholder="Template Name" />
        
        <button onClick={saveDesign}>Save Design</button>
        <button onClick={exportHtml}>Exit</button>
      </Bar>

      <React.StrictMode>
        <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
      </React.StrictMode>
    </Container>
  );
};
function copytag(_this) {
  navigator.clipboard.writeText(_this.target.value)
  
}
export default Example;
